import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import store from '@/store'
import { computed, onMounted } from 'vue'


export default {
  __name: 'DarkThemeSwitch',
  setup(__props) {

const dark = computed(() => store.dark)

function toggle () {
  localStorage.setItem('µglyphe-dark-theme', (dark.value) ? 'light' : 'dark')
  store.dark = !store.dark
}

onMounted(() => {
  if (localStorage.getItem('µglyphe-dark-theme')) {
    store.dark = localStorage.getItem('µglyphe-dark-theme') === 'dark'
  }
})

return (_ctx, _cache) => {
  const _component_unicon = _resolveComponent("unicon")

  return (_openBlock(), _createElementBlock("div", {
    class: "dark-theme-switch",
    onClick: toggle
  }, [
    (_unref(dark))
      ? (_openBlock(), _createBlock(_component_unicon, {
          key: 0,
          name: "moon"
        }))
      : (_openBlock(), _createBlock(_component_unicon, {
          key: 1,
          name: "sun"
        }))
  ]))
}
}

}