import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7301e72d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "value", "placeholder"]




export default {
  __name: 'InputComponent',
  props: {
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String
  },
  modelValue: {}
},
  emits: ['update:modelValue'],
  setup(__props) {






return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("input", {
    type: __props.type,
    value: __props.modelValue,
    placeholder: __props.placeholder,
    onInput: _cache[0] || (_cache[0] = $event => (_ctx.$emit('update:modelValue', $event.target.value)))
  }, null, 40, _hoisted_1))
}
}

}