import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72225054"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "symbol big left-parenthesis"
}
const _hoisted_2 = {
  key: 1,
  class: "symbol big right-parenthesis"
}

import MicrogestureComponent from '../MicrogestureComponent'
import { ref, provide, computed } from 'vue'


export default {
  __name: 'CompositionComponent',
  props: {
  modelValue: {
    type: Object,
    required: true
  }
},
  emits: ['update:modelValue', 'input', 'delete'],
  setup(__props, { emit: emits }) {

const props = __props;





const microgesture = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    emits('update:modelValue', value)
  }
})
const reload = ref(0)

provide('upperLevelType', microgesture.value.type)

function updateType (evt) {
  const prasedMicrogesture = JSON.parse(evt.dataTransfer.getData('microgeste'))
  if (!['and', 'or', 'then'].includes(prasedMicrogesture.type)) return
  microgesture.value.type = prasedMicrogesture.type
  emits('input', microgesture.value)
}

function addOperand (n = 1) {
  for (let i = 0; i < n; i++) {
    microgesture.value.operands.push({
      type: 'in-construction'
    })
  }
  emits('input', microgesture.value)
  reload.value++
}

function removeOperand () {
  console.log('removeOperand', microgesture.value.operands.length)
  if (microgesture.value.operands.length === 2) {
    emits('update:modelValue', microgesture.value.operands[0])
  } else {
    microgesture.value.operands.pop()
    emits('update:modelValue', microgesture.value)
    reload.value++
  }
}

provide('addOperand', addOperand)
provide('removeOperand', removeOperand)

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(microgesture).type !== 'then')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(microgesture).operands.entries(), ([i, ]) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        _createVNode(_unref(MicrogestureComponent), {
          modelValue: _unref(microgesture).operands[i],
          "onUpdate:modelValue": [
            $event => ((_unref(microgesture).operands[i]) = $event),
            _cache[0] || (_cache[0] = $event => (emits('update:modelValue', _unref(microgesture))))
          ]
        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
        (i < _unref(microgesture).operands.length - 1)
          ? (_openBlock(), _createElementBlock("span", {
              key: `symbol-${_unref(microgesture).type}-${i}`,
              class: _normalizeClass(["symbol big", _unref(microgesture).type]),
              onDrop: _cache[1] || (_cache[1] = $event => (updateType($event)))
            }, null, 34))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    (_unref(microgesture).type !== 'then')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}
}

}