import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c4975a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expand-arrows" }
const _hoisted_2 = { class: "copyright" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]

import MicroGesture from '@/components/Microgesture/MicrogestureComponent'
import { computed, ref } from 'vue'


export default {
  __name: 'ImageModal',
  props: ['microgesture'],
  setup(__props) {

const props = __props;



const displayModal = ref(false)

const imgBackground = computed(() => `background-image: url(/api/img/small/${props.microgesture.imageDescription})`)

return (_ctx, _cache) => {
  const _component_unicon = _resolveComponent("unicon")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => (displayModal.value = true), ["stop","prevent"])),
      class: "image-description",
      style: _normalizeStyle(_unref(imgBackground))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_unicon, { name: "expand-arrows-alt" })
      ])
    ], 4),
    (displayModal.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[2] || (_cache[2] = _withModifiers($event => (displayModal.value = false), ["stop","prevent"])),
          class: "modal"
        }, [
          _createVNode(_component_unicon, {
            class: "close-button",
            height: "80",
            width: "80",
            name: "times-circle"
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: `/api/img/${__props.microgesture.imageDescription}`,
              class: "modal-image"
            }, null, 8, _hoisted_3),
            _createElementVNode("p", null, [
              _createVNode(_component_unicon, {
                name: "copyright",
                style: {"position":"relative","top":"5px","fill":"white"}
              }),
              _createTextVNode(" " + _toDisplayString(__props.microgesture.paper.authors.split(',')[0]) + " et al. ", 1),
              _createElementVNode("i", null, _toDisplayString(__props.microgesture.paper.title), 1),
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                href: `https://doi.org/${__props.microgesture.paper.doi}`,
                target: "_blank"
              }, _toDisplayString(__props.microgesture.paper.doi), 9, _hoisted_4)
            ])
          ]),
          _createElementVNode("h2", null, _toDisplayString(__props.microgesture.name), 1),
          _createElementVNode("p", null, _toDisplayString(__props.microgesture.textDescription), 1),
          _createVNode(_unref(MicroGesture), {
            "model-value": __props.microgesture.µGlyphe
          }, null, 8, ["model-value"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}