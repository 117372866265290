import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74e6affc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "export-latex-button" }

import microgestureToLatex from './microgestureToLatex'
import Latex from '@/components/Latex/LatexLogo.vue'
import { ref } from 'vue'


export default {
  __name: 'ExportLatexButton',
  props: ['microgesture'],
  setup(__props) {

const props = __props;



const copied = ref(false)

function copy () {
  const msg = microgestureToLatex(props.microgesture)
  copied.value = true
  const dummy = document.createElement('input')
  document.body.appendChild(dummy)
  dummy.setAttribute('id', 'dummy_id')
  document.getElementById('dummy_id').value = msg
  dummy.select()
  document.execCommand('copy')
  setTimeout(() => {
    copied.value = false
  }, 1200)
  document.body.removeChild(dummy)
}

return (_ctx, _cache) => {
  const _component_unicon = _resolveComponent("unicon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", { onClick: copy }, [
      (!copied.value)
        ? (_openBlock(), _createBlock(Latex, { key: 0 }))
        : (_openBlock(), _createBlock(_component_unicon, {
            key: 1,
            onClick: copy,
            name: "check"
          }))
    ])
  ]))
}
}

}