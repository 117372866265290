import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/glyphs/Plus.svg'


import { ref, inject, computed } from 'vue'
import TypePicker from '@/components/Microgesture/Inputs/TypePicker.vue'
import { Action, Context } from '@/components/Microgesture/struct'


export default {
  __name: 'InConstructionComponent',
  props: { modelValue: { } },
  emits: ['update:modelValue'],
  setup(__props, { emit: emits }) {

const props = __props;





const upperLevelType = inject('upperLevelType', null)

const microgesture = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    emits('update:modelValue', value)
  }
})

const reload = ref(0)
const hoveredInputButton = inject('hoveredInputButton')
const displayActionPicker = ref(false)
const isDragOverPlus = ref(false)

const addOperand = inject('addOperand', () => {})
const removeOperand = inject('removeOperand', () => {})
const addQuantificator = inject('addQuantificator', () => {})

function onDrop (evt) {
  const microgeste = JSON.parse(evt.dataTransfer.getData('microgeste'))
  addMicrogesture(microgeste)
}

function addMicrogesture (microgeste) {
  if (microgeste.type === 'glyph') {
    microgesture.value.type = 'glyph'
    microgesture.value.action = microgeste.action ?? Action.Any
    microgesture.value.context = microgeste.context ?? [Context.Air, Context.Contact]
  } else if (['and', 'or', 'then'].includes(microgeste.type)) {
    console.log(microgeste.type, upperLevelType)
    if (microgeste.type === upperLevelType) {
      console.log('addOperand')
      addOperand()
    } else {
      microgesture.value.type = microgeste.type
    }
  } else if (microgeste.type === 'text') {
    if (upperLevelType) return
    microgesture.value.type = 'seq'
  }
  initMicrogeste()
  reload.value++
}

function initMicrogeste () {
  if (['and', 'or', 'then'].includes(microgesture.value.type)) {
    if (!microgesture.value.operands) {
      microgesture.value.operands = [
        {
          type: 'in-construction'
        },
        {
          type: 'in-construction'
        }
      ]
    } else if (microgesture.value.operands.length === 1) {
      microgesture.value.operands.push({
        type: 'in-construction'
      })
    }
  } else if (microgesture.value.type === 'seq') {
    if (!microgesture.value.quantificators) {
      microgesture.value.quantificators = { x: [[]], y: [] }
    }
    if (!microgesture.value.seq) {
      microgesture.value.seq = {
        type: 'in-construction'
      }
    }
  }
}

function updateMicrogesture (e) {
  if (e === 'seq_x' || e === 'seq_y') {
    addQuantificator(e.split('_')[1])
  } else {
    addMicrogesture({ type: e })
    emits('update:modelValue', microgesture.value)
  }
}

function destroy () {
  removeOperand()
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["drag-zone plus-symbol", [isDragOverPlus.value ? 'drag-over' : '', _unref(upperLevelType) ? 'lower-symbol' : '' ]]),
    onDrop: _cache[1] || (_cache[1] = $event => (onDrop($event))),
    onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[3] || (_cache[3] = _withModifiers($event => (isDragOverPlus.value = true), ["prevent"])),
    onDragleave: _cache[4] || (_cache[4] = _withModifiers($event => (isDragOverPlus.value = false), ["prevent"])),
    onClick: _cache[5] || (_cache[5] = $event => (displayActionPicker.value = true))
  }, [
    _createElementVNode("img", {
      src: _imports_0,
      class: _normalizeClass(['plus-button', (_unref(hoveredInputButton) === 'movement' || _unref(hoveredInputButton) === 'composition') ? 'red' : ''])
    }, null, 2),
    (displayActionPicker.value)
      ? (_openBlock(), _createBlock(TypePicker, {
          key: 0,
          "onUpdate:modelValue": updateMicrogesture,
          onClose: _cache[0] || (_cache[0] = $event => (displayActionPicker.value = false)),
          onDestroy: destroy
        }))
      : _createCommentVNode("", true)
  ], 34))
}
}

}