import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c957838"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "copy-microgesture-button" }

import { ref } from 'vue'


export default {
  __name: 'CopyButtonComponent',
  props: ['microgesture', 'unicon'],
  emits: ['copy'],
  setup(__props, { emit: emits }) {

const props = __props;




const copied = ref(false)

function copy () {
  // store.copiedMicrogesture = props.microgesture
  const msg = JSON.stringify({ microgesture: props.microgesture })
  copied.value = true
  const dummy = document.createElement('input')
  document.body.appendChild(dummy)
  dummy.setAttribute('id', 'dummy_id')
  document.getElementById('dummy_id').value = msg
  dummy.select()
  document.execCommand('copy')
  setTimeout(() => {
    copied.value = false
  }, 1200)
  document.body.removeChild(dummy)
  emits('copy')
}

return (_ctx, _cache) => {
  const _component_unicon = _resolveComponent("unicon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", { onClick: copy }, [
      (!copied.value)
        ? (_openBlock(), _createBlock(_component_unicon, {
            key: 0,
            name: props.unicon ?? 'copy'
          }, null, 8, ["name"]))
        : (_openBlock(), _createBlock(_component_unicon, {
            key: 1,
            onClick: copy,
            name: "check"
          }))
    ])
  ]))
}
}

}