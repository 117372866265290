import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3011ef8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-image" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["value"]

import { onMounted, ref, inject, computed } from 'vue'


export default {
  __name: 'InputImage',
  props: ['imageName', 'modelValue'],
  emits: ['update:modelValue', 'done'],
  setup(__props, { emit: emits }) {

const props = __props;


const axios = inject('axios')




const src = ref(props.modelValue ? props.modelValue : '')
const possibleImages = ref([])
const id = Date.now()
const reload = ref(0)
const existingImageSelected = ref(null)

const imgBackground = computed(() => {
  if (src.value && src.value.length) {
    emits('update:modelValue', src.value)
    return `background-image: url(/api/img/${src.value})`
  } else {
    return false
  }
})

function uploadImage (e) {
  existingImageSelected.value = null
  const image = e.target.files[0]

  if (image) {
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = e => {
      const formData = new FormData()
      const imageName = `${props.imageName.split(' ').join('_').toLowerCase()}.jpeg`
      formData.append('file', dataURLtoFile(e.target.result, imageName))
      axios
        .post('/api/uploadMMImage', formData, { headers: { 'Content-Type': `multipart/form-data; boundary=${formData._boundary}` } })
        .then(() => {
          src.value = imageName
          reload.value++
          emits('done')
        })
        .catch((err) => {
          emits('done', { err: err })
        })
    }
  }
}

function dataURLtoFile (dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

onMounted(() => {
  axios.get('/api/imgs').then((l) => { possibleImages.value = l.data })
})

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_openBlock(), _createElementBlock("div", {
      class: "image-description",
      style: _normalizeStyle(_unref(imgBackground)),
      key: reload.value
    }, null, 4)),
    _createElementVNode("input", {
      id: `image-upload-${_unref(id)}`,
      type: "file",
      accept: "image/png, image/jpeg",
      onChange: uploadImage
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: `image-upload-${_unref(id)}`
    }, " Upload Image ", 8, _hoisted_3),
    _createElementVNode("div", null, [
      _createTextVNode(" Already uploaded ? "),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((src).value = $event))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(possibleImages.value, (image) => {
          return (_openBlock(), _createElementBlock("option", {
            key: image,
            value: image
          }, _toDisplayString(image), 9, _hoisted_4))
        }), 128))
      ], 512), [
        [_vModelSelect, src.value]
      ])
    ])
  ]))
}
}

}