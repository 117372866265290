import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b73be276"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id", "innerHTML"]
const _hoisted_5 = ["id"]

import { Action, Context } from '../struct'
import ActionPicker from '../Inputs/ActionPicker'
import ContextPicker from '../Inputs/ContextPicker'
import ActuatorInput from '../Inputs/ActuatorInput'
import PhalanxInput from '../Inputs/PhalanxInput'
import ParameterInput from '../Inputs/MovementParametersInput'
import { ref, onBeforeMount, onMounted, inject, reactive, computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getFancyActuator, getFancyPhalanx, getFancyParameters } from '../utils'


export default {
  __name: 'GlyphComponent',
  props: {
  modelValue: {
    required: true
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: emits }) {

const props = __props;





const hoveredInputButton = inject('hoveredInputButton', false)
const inConstruction = inject('inConstruction', false)

const id = uuidv4()
const glyph = reactive(props.modelValue)
const reload = ref(0)
const displayedPicker = ref(null)
const widths = reactive({
  actuator: 0,
  phalanx: 0,
  parameters: 16
})

const actionImg = computed(() => {
  switch (glyph.action) {
    case Action.Flexion:
      return require('../assets/glyphs/Flexion.svg')
    case Action.Extension:
      return require('../assets/glyphs/Extension.svg')
    case Action.Abduction:
      return require('../assets/glyphs/Abduction.svg')
    case Action.Adduction:
      return require('../assets/glyphs/Adduction.svg')
    case Action.Still:
      return require('../assets/glyphs/Still.svg')
    case Action.Any:
      return require('../assets/glyphs/Any.svg')
    case Action.CircleCW:
      return require('../assets/glyphs/CircleCW.svg')
    case Action.CircleCCW:
      return require('../assets/glyphs/CircleCCW.svg')
    case Action.SquareCW:
      return require('../assets/glyphs/SquareCW.svg')
    case Action.SquareCCW:
      return require('../assets/glyphs/SquareCCW.svg')
    case Action.TriangleCW:
      return require('../assets/glyphs/TriangleCW.svg')
    case Action.TriangleCCW:
      return require('../assets/glyphs/TriangleCCW.svg')
    case Action.Zigzag:
      return require('../assets/glyphs/Zigzag.svg')
    default:
      return false
  }
})

const contextImg = computed(() => {
  if (!glyph.context) return []
  const temp = []
  for (const ctx of glyph.context) {
    switch (ctx) {
      case Context.Air:
        temp.push(require('../assets/glyphs/Air.svg'))
        break
      case Context.Contact:
        temp.push(require('../assets/glyphs/Contact.svg'))
        break
      case Context.Any:
        temp.push(require('../assets/glyphs/Any.svg'))
        break
      default:
    }
  }
  return temp
})

function displayPicker (type) {
  if (!inConstruction) return
  displayedPicker.value = type
}

function closePicker () {
  displayedPicker.value = null
  emits('update:modelValue', glyph)
}

const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    const { width } = entry.contentRect
    if (entry.target.id === `${id}-actuator`) {
      widths.actuator = width
    } else if (entry.target.id === `${id}-phalanx`) {
      widths.phalanx = width
    } else if (entry.target.id === `${id}-parameters`) {
      widths.parameters = width
    }
  }
})

function onDrop (evt) {
  const microgeste = JSON.parse(evt.dataTransfer.getData('microgeste'))
  if (microgeste.type === 'glyph') {
    glyph.action = microgeste.action
  } else if (microgeste.type === 'contact' || microgeste.type === 'object') {
    glyph.contact = { type: microgeste.type, action: microgeste.type }
    reload.value++
  } else {
    emits('addParent', microgeste)
  }
  emits('update:modelValue', glyph)
}

function initGlyph () {
  if (inConstruction) {
    if (!glyph.context && glyph.action && !['object', 'contact'].includes(glyph.action)) {
      if (glyph.action === 'Still') {
        glyph.context = ['Contact']
      } else {
        glyph.context = ['Air', 'Contact']
      }
    }
    if (!glyph.parameters) {
      glyph.parameters = {
        pressure: { start: null, end: null, type: 'no_end' },
        amplitude: { start: null, end: null, type: 'no_end' },
        time: { start: null, end: null, leftType: 'none', rightType: 'bigger' }
      }
    }
    if (!glyph.actuator) {
      glyph.actuator = []
    }
    if (!glyph.phalanx) {
      glyph.phalanx = []
    }
  }
}

function destroy () {
  for (const x of Object.keys(glyph)) {
    delete glyph[x]
  }
  // eslint-disable-next-line vue/no-mutating-props
  glyph.type = 'in-construction'
  emits('update:modelValue', glyph)
}

onBeforeMount(initGlyph)

onMounted(() => {
  resizeObserver.observe(document.getElementById(`${id}-actuator`))
  resizeObserver.observe(document.getElementById(`${id}-phalanx`))
  resizeObserver.observe(document.getElementById(`${id}-parameters`))
})

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["glyph-container", 
      (_unref(inConstruction) && (_unref(hoveredInputButton) === 'seq' || _unref(hoveredInputButton) === 'composition')) ? 'red' : '']),
    onDragover: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"])),
    onDrop: _cache[12] || (_cache[12] = $event => (onDrop($event)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["glyph", (_unref(inConstruction)) ? 'in-construction' : '']),
      style: _normalizeStyle(`
            padding-left:${widths.actuator}px;
            padding-right:${widths.phalanx}px;
           `)
    }, [
      (_openBlock(), _createElementBlock("div", {
        class: "context",
        onClick: _cache[0] || (_cache[0] = $event => (displayPicker('context'))),
        key: reload.value
      }, [
        (_unref(contextImg) && _unref(contextImg).length)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(contextImg).entries(), ([i, src]) => {
              return (_openBlock(), _createElementBlock("img", {
                key: i,
                src: src
              }, null, 8, _hoisted_1))
            }), 128))
          : _createCommentVNode("", true)
      ])),
      _createElementVNode("div", {
        class: "action",
        onClick: _cache[1] || (_cache[1] = $event => (displayPicker('action')))
      }, [
        (_openBlock(), _createElementBlock("img", {
          src: _unref(actionImg),
          key: reload.value
        }, null, 8, _hoisted_2))
      ]),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = $event => (displayPicker('actuator'))),
        id: `${_unref(id)}-actuator`,
        class: "actuator"
      }, _toDisplayString(_unref(getFancyActuator)(glyph.actuator, _unref(inConstruction))), 9, _hoisted_3),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = $event => (displayPicker('parameters'))),
        id: `${_unref(id)}-parameters`,
        class: "parameters",
        style: _normalizeStyle(`left:${widths.actuator + 8}px;`),
        innerHTML: _unref(getFancyParameters)(glyph.parameters, _unref(inConstruction))
      }, null, 12, _hoisted_4),
      _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = $event => (displayPicker('phalanx'))),
        id: `${_unref(id)}-phalanx`,
        class: "phalanx"
      }, _toDisplayString(_unref(getFancyPhalanx)(glyph.phalanx, _unref(inConstruction))), 9, _hoisted_5)
    ], 6),
    (displayedPicker.value === 'action')
      ? (_openBlock(), _createBlock(_unref(ActionPicker), {
          key: 0,
          modelValue: glyph.action,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((glyph.action) = $event)),
          onClose: closePicker,
          onDestroy: destroy
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (displayedPicker.value === 'context')
      ? (_openBlock(), _createBlock(_unref(ContextPicker), {
          key: 1,
          action: glyph.action,
          modelValue: glyph.context,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((glyph.context) = $event)),
          onClose: closePicker
        }, null, 8, ["action", "modelValue"]))
      : _createCommentVNode("", true),
    (displayedPicker.value === 'actuator')
      ? (_openBlock(), _createBlock(_unref(ActuatorInput), {
          key: 2,
          modelValue: glyph.actuator,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((glyph.actuator) = $event)),
          onClose: closePicker
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (displayedPicker.value === 'phalanx')
      ? (_openBlock(), _createBlock(_unref(PhalanxInput), {
          key: 3,
          modelValue: glyph.phalanx,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((glyph.phalanx) = $event)),
          onClose: closePicker
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (displayedPicker.value === 'parameters')
      ? (_openBlock(), _createBlock(_unref(ParameterInput), {
          key: 4,
          modelValue: glyph.parameters,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((glyph.parameters) = $event)),
          onClose: closePicker
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 34))
}
}

}