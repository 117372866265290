import { Action, Contact, Context } from '@/components/Microgesture/struct'
import { getFancyActuator, getFancyParameters, getFancyPhalanx } from '@/components/Microgesture/utils'

function contextToLatex (context) {
  switch (context) {
    case Context.Air:
      return '\\air'
    case Context.Contact:
      return '\\contact'
    case Context.Any:
      return '-'
  }
}

function actuatorToLatex (actuator) {
  try {
    return getFancyActuator(actuator).replaceAll(' ', '')
  } catch {
    return ''
  }
}

function phalanxToLatex (phalanx) {
  try {
    return getFancyPhalanx(phalanx).replaceAll(' ', '')
  } catch {
    return ''
  }
}

function parametersToLatex (parameters) {
  return getFancyParameters(parameters)
    .replaceAll('→', '\\rightarrow')
    .replaceAll('<div>', '{$')
    .replaceAll('</div>', '$}')
}

function glyphToLatex (glyph) {
  console.log(glyph)
  let s = ''
  let customEvent = ''
  switch (glyph.action) {
    case Action.Any:
      s = '\\any'
      break
    case Action.Flexion:
      s = '\\flx'
      break
    case Action.Extension:
      s = '\\ext'
      break
    case Action.Abduction:
      s = '\\abd'
      break
    case Action.Adduction:
      s = '\\add'
      break
    case Action.Still:
      s = '\\stl'
      break
    case Action.CircleCW:
      s = '\\customEvt'
      customEvent = '{\\circlearrowright}'
      break
    case Action.CircleCCW:
      s = '\\customEvt'
      customEvent = '{\\circlearrowleft}'
      break
    case Action.SquareCW:
      s = '\\customEvt'
      customEvent = '{\\customMVT{./customGlyph/rectcw.png}}'
      break
    case Action.SquareCCW:
      s = '\\customEvt'
      customEvent = '{\\customMVT{./customGlyph/rectccw.png}}'
      break
    case Action.TriangleCW:
      s = '\\customEvt'
      customEvent = '{\\customMVT{./customGlyph/triancw.png}}'
      break
    case Action.TriangleCCW:
      s = '\\customEvt'
      customEvent = '{\\customMVT{./customGlyph/trianccw.png}}'
      break
    case Action.Zigzag:
      s = '\\customEvt'
      customEvent = '{\\customMVT{./customGlyph/zigzag.png}}'
      break
    case Contact.Object:
      s = '\\obj'
      break
    case Contact.Air:
      s = '\\cta'
      break
    default:
      s = '\\ctc'
  }
  const ph = phalanxToLatex(glyph.phalanx)
  const a = actuatorToLatex(glyph.actuator)
  const p = parametersToLatex(glyph.parameters)
  console.log(ph, a, p)
  if (p && p.length) s += `[${ph}]` + `[${a}]` + `[${p}]`
  else if (a && a.length) s += `[${ph}]` + `[${a}]`
  else if (ph && ph.length) s += `[${ph}]`

  s += `{${glyph.context ? glyph.context.map(x => contextToLatex(x)).join(' ') : ''}}`
  s += customEvent

  if (glyph.contact) {
    s += `(${glyphToLatex(glyph.contact)})`
  }
  return s
}

function glyphToSituatedLatex (glyph) {
  if (!glyph.actuator || !glyph.actuator.length) return glyphToLatex(glyph)
  let s = ''
  let palmLatex = ''

  const glyphCmd = glyphToLatex({ action: glyph.action, context: glyph.context, phalanx: glyph.phalanx, parameters: glyph.parameters })
  let glyphContact = ''
  if (glyph.contact) {
    glyphContact = glyphToLatex({ phalanx: glyph.contact.phalanx, parameters: glyph.contact.parameters })
  }

  for (const x of ['thumb', 'index', 'middle', 'ring', 'pinky']) {
    if (glyph.actuator[0].includes(x)) {
      s += `{${glyphCmd}}`
    } else if (glyph.contact && glyph.contact.actuator && glyph.contact.actuator.length && glyph.contact.actuator[0].includes(x)) {
      s += `{${glyphContact}}`
    } else {
      s += '{\\finger}'
    }
  }

  if (
    glyph.contact &&
    glyph.contact.phalanx &&
    glyph.contact.phalanx.length &&
    glyph.contact.phalanx[0][0].segment === 'palm') {
    palmLatex = '\\contact'
  }
  return `\\MVT[${palmLatex}][]${s}`
}

function microgestureToLatex (microgesture, situated = false, firstCall = true) {
  console.log(microgesture, situated)
  if (microgesture.type === 'in-construction') {
    return '+'
  } else if (microgesture.type === 'glyph') {
    if (situated) {
      return glyphToSituatedLatex(microgesture)
    } else {
      return glyphToLatex(microgesture)
    }
  } else if (['and', 'or', 'then'].includes(microgesture.type)) {
    const temp = []
    for (const op of microgesture.operands) {
      temp.push(microgestureToLatex(op, situated, false))
    }
    let s = ''
    if (!firstCall) {
      s += '\\Big('
    }
    if (microgesture.type === 'and') {
      s += temp.join('||')
    } else if (microgesture.type === 'or') {
      s += temp.join('|')
    } else if (microgesture.type === 'then') {
      s += temp.join(';')
    }
    if (!firstCall) {
      s += '\\Big)'
    }
    return s
  } else if (microgesture.type === 'seq') {
    let s = '\\text{'
    if (microgesture.quantificators.x) {
      for (const [i, x] of microgesture.quantificators.x.entries()) {
        if (microgesture.quantificators.x.length === 1) {
          s += 'x {$\\in$} \\{'
        } else {
          s += `x${i} {$\\in$} \\{`
        }
        s += actuatorToLatex(x)
        s += '\\}, '
      }
    }

    if (microgesture.quantificators.y) {
      for (const [i, y] of microgesture.quantificators.y.entries()) {
        if (microgesture.quantificators.y.length === 1) {
          s += 'y {$\\in$} \\{'
        } else {
          s += `y${i} {$\\in$} \\{`
        }
        s += phalanxToLatex(y)
        s += '\\}, '
      }
    }
    return s + microgestureToLatex(microgesture.seq, situated, true)
  }
}

export default microgestureToLatex
