import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Nav from '@/components/NavComponent.vue'
import store from '@/store'
import { inject, computed, onMounted } from 'vue'


export default {
  __name: 'App',
  setup(__props) {

const axios = inject('axios')
const dark = computed(() => store.dark)

onMounted(() => {
  axios.get('/api/login').then(response => {
    store.isLogged = response.data.status
  }).catch(() => {
  })
})

return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: _normalizeClass((_unref(dark)) ? 'dark' : '')
  }, [
    _createVNode(Nav),
    _createVNode(_component_router_view)
  ], 2))
}
}

}