import { Context, Action } from './struct.js'

function actutorPhalanxMatchModel (model, actuatorPhalanx, options) {
  if (model.length < actuatorPhalanx.length) return false

  model = JSON.parse(JSON.stringify(model))
  for (const x of model) {
    if (Object.keys(options).includes(x[0])) {
      x.push(...options[x[0]])
      x.shift()
    }
    if (Object.keys(options).includes(x[0]?.segment)) {
      x.push(...options[x[0]?.segment])
      x.shift()
    }
    if (x.length === actuatorPhalanx[0].length) {
      let macthAll = 0
      for (let i = 0; i < x.length; i++) {
        if (typeof x[i] === 'string' && // actuator
            x[i] === actuatorPhalanx[0][i]) {
          macthAll++
        } else if (typeof x[i] === 'object' && // phalanx
              x[i].segment === actuatorPhalanx[0][i].segment &&
            (!x[i].side || x[i].side === actuatorPhalanx[0][i].side)) {
          macthAll++
        }
      }
      if (macthAll === x.length) return true
    }
  }
  return false
}

function glyphMatchModel (model, glyph, options) {
  if (model.type !== 'glyph' || glyph.type !== 'glyph') return false
  if (model.context.length !== glyph.context.length) return false

  // Check context
  for (let i = 0; i < model.context.length; i++) {
    if (model.context[i] !== Context.Any &&
        model.context[i] !== glyph.context[i]) {
      return false
    }
  }

  // Check action
  if (model.action !== Action.Any && model.action !== glyph.action) return false

  // Check actuator
  if (model.actuator) {
    if (!glyph.actuator || !actutorPhalanxMatchModel(model.actuator, glyph.actuator, options)) return false
  }

  // Check parameters
  if (model.parameter) {
    if (!glyph.parameter) return false
    for (const key of Object.keys(model.parameter)) {
      if (!glyph.parameter[key]) return false
      for (const key2 of Object.keys(model.parameter[key])) {
        if (model.parameter[key][key2] && model.parameter[key][key2] !== glyph.parameter[key][key2]) {
          return false
        }
      }
    }
  }

  // Check phalanx
  if (model.phalanx && model.phalanx.length) {
    if (!glyph.phalanx || !actutorPhalanxMatchModel(model.phalanx, glyph.phalanx, options)) return false
  }

  if (model.contact) {
    if (!glyph.contact) return false
    if (model.contact.type !== glyph.contact.type) return false
    if (model.contact.actuator) {
      if (!glyph.contact.actuator || !actutorPhalanxMatchModel(model.contact.actuator, glyph.contact.actuator, options)) return false
    }
    if (model.contact.phalanx && model.contact.phalanx.length) {
      if (!glyph.contact.phalanx || !actutorPhalanxMatchModel(model.contact.phalanx, glyph.contact.phalanx, options)) return false
    }
  }

  return true
}

/**
 * Requires canocical form of microgeture
 **/
function microgestureMatchModel (model, microgesture, options = {}) {
  if (model.type === 'seq') {
    if (model.quantificators.x?.length) {
      for (const [i, x] of model.quantificators.x.entries()) {
        for (const xOr of x) {
          options[`x${model.quantificators.x.length === 1 ? '' : i}`] = xOr
          if (microgestureMatchModel(model.seq, microgesture, options)) return true
        }
      }
      return false
    }
    if (model.quantificators.y?.length) {
      for (const [i, y] of model.quantificators.y.entries()) {
        for (const yOr of y) {
          options[`y${model.quantificators.y.length === 1 ? '' : i}`] = yOr
          if (microgestureMatchModel(model.seq, microgesture, options)) return true
        }
      }
      return false
    }
    return false
  }

  if (model.type !== microgesture.type) return false

  if (model.type === 'glyph') {
    return glyphMatchModel(model, microgesture, options)
  } else if (['and', 'or', 'then'].includes(model.type)) {
    if (model.operands.length !== microgesture.operands.length) {
      return false
    }
    for (let i = 0; i < model.operands.length; i++) {
      if (!microgestureMatchModel(model.operands[i], microgesture.operands[i], options)) { return false }
    }
    return true
  }
  return false
}

export {
  glyphMatchModel,
  microgestureMatchModel
}

// const model = {
//   'type': 'seq',
//   'quantificators': { 'x': [ [ ['i'], ['m'] ] ], 'y': [] },
//   'seq': {
//     'type': 'glyph',
//     'context': [ 'Air', 'Contact' ],
//     'action': 'Extension',
//     'actuator': [['x']] } }
//
// const test = {
//   'type': 'glyph',
//   'context': [ 'Air', 'Contact' ],
//   'action': 'Extension',
//   'actuator': [['m']] }
//
// console.log(microgestureMatchModel(model, test))

// const model = {'type':'seq','quantificators':
//       {'y':[[[{'segment':'tip','side':''}],[{'segment':'bottom','side':''}]]]},
// 'seq':{
//   'type':'then',
//   'operands':[
//     {'type':'glyph','action':'Flexion','context':['Air','Contact'],'actuator':[['thumb']],'contact':{'type':'contact','action':'Contact','actuator':[['index']],'phalanx':[[{'segment':'y','side':''}]]},'parameters':{'pressure':{'start':null,'end':null,'type':'no_end'},'amplitude':{'start':null,'end':null,'type':'no_end'},'time':{'start':null,'end':null,'leftType':'none','rightType':'bigger'}},'phalanx':[]},
//     {'type':'glyph','action':'Extension','context':['Contact','Air'],'actuator':[['thumb']],'contact':{'type':'contact','action':'Air','actuator':[['index']],'phalanx':[[{'segment':'y','side':''}]]},'parameters':{'pressure':{'start':null,'end':null,'type':'no_end'},'amplitude':{'start':null,'end':null,'type':'no_end'},'time':{'start':null,'end':null,'leftType':'none','rightType':'bigger'}},'phalanx':[]}]}}

// const raw =  {'type':'then',
//   'operands':[
//     {'type':'glyph','action':'Flexion','context':['Air','Contact'],'actuator':[['thumb']],'contact':{'type':'contact','action':'Contact','actuator':[['index']],'phalanx':[[{'side':'front','segment':'bottom'}]]}},
//     {'type':'glyph','action':'Extension','context':['Contact','Air'],'actuator':[['thumb']],'contact':{'type':'contact','action':'Air','actuator':[['index']],'phalanx':[[{'side':'front','segment':'bottom'}]]}}]}
//

// const raw = {'type':'then','operands':[{'type':'glyph','action':'Flexion','context':['Air','Contact'],'actuator':[['thumb']],'contact':{'type':'contact','action':'Contact','actuator':[['index']],'phalanx':[[{'side':'front','segment':'base'}]]}},{'type':'glyph','action':'Extension','context':['Contact','Air'],'actuator':[['thumb']],'contact':{'type':'contact','action':'Air','actuator':[['index']],'phalanx':[[{'side':'front','segment':'base'}]]}}]}
// console.log(microgestureMatchModel(model, raw))
