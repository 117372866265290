function getFancyActuator (actuator, inConstruction = false) {
  try {
    const actuators = ['thumb', 'index', 'middle', 'ring', 'pinky']
    if ((!actuator || actuator.length === 0) && inConstruction) {
      return '+'
    }
    return actuator
      .map(x =>
        x.map(y => actuators.includes(y) ? y.slice(0, 1) : (y.length) ? y : '_')
          .join('//'))
      .join(' | ')
  } catch (e) {
    if (inConstruction) {
      return '+'
    }
    return ''
  }
}

function getFancyPhalanx (phalanx, inConstruction = false) {
  try {
    if (!phalanx || phalanx.length === 0) {
      return inConstruction ? '+' : ''
    }

    const s = []
    for (const and of phalanx) {
      const temp = []
      for (const location of and) {
        if (location.side && !location.segment) {
          temp.push(location.side.slice(0, 1))
        } else if (location.side === 'front') {
          temp.push(location.segment.slice(0, 1))
        } else if (location.side === '' && ['tip', 'middle', 'base'].includes(location.segment)) {
          temp.push(location.segment.slice(0, 1))
        } else if (location.side === 'dorsal' && location.segment === 'tip') {
          temp.push('n')
        } else if (location.segment === 'palm') {
          temp.push(location.segment.slice(0, 1))
        } else if (location.side === '' && location.segment === '') {
          temp.push('_')
        } else if (location.side === '') {
          temp.push(location.segment)
        } else {
          temp.push(`${location.segment.slice(0, 1)} ${location.side.slice(0, 1)}`)
        }
      }
      s.push(temp.join('//'))
    }
    return s.join(' | ')
  } catch (e) {
    if (inConstruction) {
      return '+'
    }
    return ''
  }
}

function getFancyParameters (parameters, inConstruction) {
  try {
    const s = []
    let n = 0
    if (parameters.pressure.start) {
      let temp = parameters.pressure.start
      if (parameters.pressure.type === 'end') {
        temp += ' → '
        temp += parameters.pressure.end
      }
      s.push(temp)
    } else {
      n++
    }

    if (parameters.amplitude.start || parameters.amplitude.end) {
      let temp = ''
      if (parameters.amplitude.start) {
        temp += parameters.amplitude.start
        if (!isNaN(parameters.amplitude.start))temp += '%'
      }
      if (parameters.amplitude.type === 'end') {
        temp += ' → '
        temp += parameters.amplitude.end
        if (!isNaN(parameters.amplitude.end)) temp += '%'
      }
      s.push(temp)
    } else {
      n++
    }

    if (parameters.time.end) {
      let temp = ''
      if (parameters.time.leftType !== 'none') {
        temp += parameters.time.start
        temp += parameters.time.leftType === 'lower' ? 'ms ＜ ' : ' ≤ '
      }
      temp += 't'
      switch (parameters.time.rightType) {
        case 'equal':
          temp += ' = '
          break
        case 'bigger':
          temp += '＜'
          break
        case 'bigger_equal':
          temp += ' ≤ '
          break
      }
      temp += parameters.time.end
      temp += 'ms'
      s.push(temp)
    } else {
      n++
    }

    if (n === 3 && inConstruction) return '+'
    return s.map(x => `<div>${x}</div>`).join('')
  } catch {
    if (inConstruction) return '+'
    return ''
  }
}

export {
  getFancyActuator,
  getFancyPhalanx,
  getFancyParameters
}
