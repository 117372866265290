import { createRouter, createWebHistory } from 'vue-router'
import Table from '@/views/TableView.vue'
import AddPaper from '@/views/AddPaper/AddPaperView.vue'
import Reader from '@/views/ReaderView.vue'
import LatexExport from '@/views/LatexExportView.vue'
import Statistics from '@/views/StatisticsView.vue'
import Contact from '@/views/ContactView.vue'
import LessonView from '@/views/LessonView.vue'
import LoginView from '@/views/LoginView.vue'
import store from '@/store'
import axios from 'axios'
import PapersView from '@/views/PapersView.vue'
import WordExportView from '@/views/WordExportView.vue'

const routes = [
  {
    path: '/',
    name: 'Table',
    component: Table
  },
  {
    path: '/papers',
    name: 'Paper',
    component: PapersView
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AddPaper,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reader',
    name: 'Reader',
    component: Reader
  },
  {
    path: '/to_latex',
    name: 'ToLatex',
    component: LatexExport
  },
  {
    path: '/to_word',
    name: 'ToWord',
    component: WordExportView
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/lesson',
    name: 'Lesson',
    component: LessonView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    axios.get('/api/login').then(response => {
      store.isLogged = response.data.status
      store.isModo = response.data.isModo
      if (!store.isLogged) {
        next({
          name: 'Login',
          params: { next: to.fullPath }
        })
      } else {
        next()
      }
    }).catch(() => {
      next({
        name: 'Login',
        params: { next: to.fullPath }
      })
    })
  } else {
    next()
  }
})

export default router
