import { unref as _unref, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

import GlyphComponent from './MicrogestureComponents/GlyphComponent'
import CompositionComponent from './MicrogestureComponents/CompositionComponent'
import VariablesComponent from './MicrogestureComponents/VariablesComponent'
import { ref, onMounted, onBeforeMount, onBeforeUnmount, inject, computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import InConstructionComponent from './MicrogestureComponents/InConstructionComponent'
import ContactComponent from './MicrogestureComponents/ContactComponent'


export default {
  __name: 'MicrogestureComponent',
  props: ['modelValue'],
  emits: ['update:modelValue', 'addOperand', 'addParent'],
  setup(__props, { emit: emits }) {

const props = __props;





const id = uuidv4()
const reload = ref(0)
const microgesture = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    emits('update:modelValue', value)
    reload.value++
  }
})

const microgestureComponent = inject('microgestureComponent', {})
const inConstruction = inject('inConstruction', false)

function addParent (microgesture) {
  const microgestureSave = JSON.parse(JSON.stringify(microgesture.value))
  if (['and', 'or', 'then'].includes(microgesture.type)) {
    microgesture.value.type = microgesture.type
    microgesture.value.operands = [microgestureSave]
  } else if (microgesture.type === 'text') {
    microgesture.value.type = 'seq'
    microgesture.value.seq = microgestureSave
  }
  initMicrogesture()
  reload.value++
}

function initMicrogesture () {
  if (['and', 'or', 'then'].includes(microgesture.value.type)) {
    if (!microgesture.value.operands) {
      microgesture.value.operands = [
        {
          type: 'in-construction'
        },
        {
          type: 'in-construction'
        }
      ]
    } else if (microgesture.value.operands.length === 1) {
      microgesture.value.operands.push({
        type: 'in-construction'
      })
    }
  } else if (microgesture.value.type === 'seq') {
    if (!microgesture.value.quantificators) {
      microgesture.value.quantificators = { x: [[]], y: [] }
    }
    if (!microgesture.value.seq) {
      microgesture.value.seq = {
        type: 'in-construction'
      }
    }
  }
}

function updateContact () {
  emits('update:modelValue', microgesture.value)
  reload.value++
}

onBeforeMount(() => {
  initMicrogesture()
})

onMounted(() => {
  if (inConstruction.value) {
    microgestureComponent.value[id] = microgesture
  }
})

onBeforeUnmount(() => {
  if (inConstruction.value) {
    delete microgestureComponent.value[id]
  }
})

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["microgesture", [
      (_unref(inConstruction)) ? 'in-construction' : '',
      (_unref(microgesture).type === 'seq') ? 'seq' : ''
      ]]),
    key: reload.value
  }, [
    (_unref(microgesture).type === 'seq')
      ? (_openBlock(), _createBlock(_unref(VariablesComponent), {
          key: 0,
          modelValue: _unref(microgesture),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (_isRef(microgesture) ? (microgesture).value = $event : null))
        }, null, 8, ["modelValue"]))
      : (_unref(microgesture).type === 'glyph')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_unref(GlyphComponent), {
              modelValue: _unref(microgesture),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_isRef(microgesture) ? (microgesture).value = $event : null)),
              onAddParent: addParent
            }, null, 8, ["modelValue"]),
            (_unref(microgesture).contact || _unref(inConstruction))
              ? (_openBlock(), _createBlock(_unref(ContactComponent), {
                  key: 0,
                  modelValue: _unref(microgesture).contact,
                  "onUpdate:modelValue": [
                    _cache[2] || (_cache[2] = $event => ((_unref(microgesture).contact) = $event)),
                    updateContact
                  ]
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ], 64))
        : (['and', 'or', 'then'].includes(_unref(microgesture).type))
          ? (_openBlock(), _createBlock(_unref(CompositionComponent), {
              key: 2,
              modelValue: _unref(microgesture),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (_isRef(microgesture) ? (microgesture).value = $event : null))
            }, null, 8, ["modelValue"]))
          : (_unref(inConstruction))
            ? (_openBlock(), _createBlock(_unref(InConstructionComponent), {
                key: 3,
                modelValue: _unref(microgesture),
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (_isRef(microgesture) ? (microgesture).value = $event : null))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

}