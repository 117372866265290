import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import MicrogestureInput from '@/components/Microgesture/MicrogestureInput'
import { computed, inject, onMounted, ref } from 'vue'
import { microgestureMatchModel } from '@/components/Microgesture/testMicrogestures'


export default {
  __name: 'StatisticsView',
  setup(__props) {

const axios = inject('axios')

const allMM = ref([])
const papers = ref([])
const searchValue = ref(null)
// eslint-disable-next-line no-unused-vars
const touch = {
  type: 'glyph',
  action: 'Flexion',
  context: ['Air', 'Contact']
}
const tap = {
  type: 'then',
  operands: [
    {
      type: 'glyph',
      action: 'Flexion',
      context: ['Air', 'Contact']
    }, { type: 'glyph', action: 'Extension', context: ['Contact', 'Air'] }]
}
// eslint-disable-next-line no-unused-vars
const drag = {
  type: 'glyph',
  action: 'Any',
  context: ['Contact', 'Contact']
}
// eslint-disable-next-line no-unused-vars
const swipe = {
  type: 'then',
  operands: [
    {
      type: 'glyph',
      action: 'Any',
      context: ['Air', 'Contact']
    },
    {
      type: 'glyph',
      action: 'Any',
      context: ['Contact', 'Contact']
    }, {
      type: 'glyph',
      action: 'Any',
      context: ['Contact', 'Air']
    }]
}

const nbMicrogestures = computed(() => allMM.value.length)
const nbPapers = computed(() => papers.value.length)
// const nbTouch = computed(() => searchMicrogesture(touch).length)
const nbTap = computed(() => searchMicrogesture(tap).length)
// const nbSwipe = computed(() => searchMicrogesture(swipe).length)
// const nbDrag = computed(() => searchMicrogesture(drag).length)

function searchMicrogesture (microgesture) {
  return allMM.value.slice()
    .filter(x => {
      console.log(x.µGlyphe, microgestureMatchModel(microgesture, x.µGlyphe))
      return microgestureMatchModel(microgesture, x.µGlyphe)
    })
}

onMounted(() => {
  axios.get('/api/literature')
    .then((d) => {
      papers.value = d.data
      for (const lit of d.data) {
        if (lit.mmList) {
          allMM.value.push(...lit.mmList.map(x => {
            return { ...x, paper: lit }
          }))
        }
      }
    })
    .catch(err => console.log(err))
})

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_unref(MicrogestureInput), {
      modelValue: searchValue.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((searchValue).value = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("p", null, _toDisplayString(searchValue.value), 1),
    _createElementVNode("p", null, "Number of microgesture kind: " + _toDisplayString(_unref(nbMicrogestures)), 1),
    _createElementVNode("p", null, "Number of paper: " + _toDisplayString(_unref(nbPapers)), 1),
    _createElementVNode("p", null, "Number of touch: " + _toDisplayString(_ctx.nbTouch), 1),
    _createElementVNode("p", null, "Number of tap: " + _toDisplayString(_unref(nbTap)), 1),
    _createElementVNode("p", null, "Number of drag: " + _toDisplayString(_ctx.nbDrag), 1),
    _createElementVNode("p", null, "Number of swipe: " + _toDisplayString(_ctx.nbSwipe), 1)
  ]))
}
}

}