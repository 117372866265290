const Action = {
  Extension: 'Extension',
  Flexion: 'Flexion',
  Abduction: 'Abduction',
  Adduction: 'Adduction',
  Still: 'Still',
  Any: 'Any',
  CircleCW: 'CircleCW',
  CircleCCW: 'CircleCCW',
  TriangleCW: 'TriangleCW',
  TriangleCCW: 'TriangleCCW',
  SquareCW: 'SquareCW',
  SquareCCW: 'SquareCCW',
  Zigzag: 'Zigzag'
}

const Context = {
  Air: 'Air',
  Contact: 'Contact',
  Any: 'Any'
}

const Contact = {
  Air: 'Air',
  Contact: 'Contact',
  Object: 'Object'
}

export {
  Action,
  Context,
  Contact
}
