import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03bf1335"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form" }

import MMList from '@/views/AddPaper/MMList'
import PaperDescription from '@/views/AddPaper/PaperDescription'

import { reactive, ref } from 'vue'
import router from '@/router'


export default {
  __name: 'AddPaperView',
  setup(__props) {

const views = [PaperDescription, MMList]
const step = ref(0)
const transition = ref('next')
const errorMsg = ref(false)
const loading = ref(false)
let formData = reactive({})

function next (data) {
  formData = {
    ...formData,
    ...data
  }
  console.log(data)
  step.value += 1
  transition.value = 'next'
}

function previous () {
  step.value -= 1
  transition.value = 'previous'
}

function error (error) {
  console.log('e', error)
  loading.value = false
  step.value = this.views.length - 1
  transition.value = 'next'
  errorMsg.value = error
}

function confirm (next) {
  loading.value = false
  router.push(next)
}

function toggleLoading () {
  loading.value = true
}

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_Transition, {
      name: transition.value,
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(views[step.value]), {
          onNext: next,
          onPrevious: previous,
          onError: error,
          onConfirm: confirm,
          onLoading: toggleLoading,
          errorMsg: errorMsg.value,
          data: _unref(formData)
        }, null, 40, ["errorMsg", "data"]))
      ]),
      _: 1
    }, 8, ["name"])
  ]))
}
}

}