import App from './App.vue'
import { createApp } from 'vue'
import router from './router'
import store from './store'

import axios from 'axios'

import Unicon from 'vue-unicons'
import {
  uniPlus,
  uniTimes,
  uniExternalLinkAlt,
  uniCancel,
  uniCheck,
  uniCheckCircle,
  uniCopy,
  uniPlusCircle,
  uniMinusCircle,
  uniTimesCircle,
  uniSearch,
  uniMoon,
  uniSun,
  uniAngleRight,
  uniAngleDown,
  uniAngleLeft,
  uniExpandArrowsAlt,
  uniClipboardNotes,
  uniPlayCircle,
  uniCopyright,
  uniSort,
  uniFileCheckAlt,
  uniTrashAlt,
  uniSync
} from 'vue-unicons/dist/icons'

Unicon.add([
  uniPlus,
  uniTimes,
  uniExternalLinkAlt,
  uniCancel,
  uniCheck,
  uniCheckCircle,
  uniCopy,
  uniPlusCircle,
  uniMinusCircle,
  uniTimesCircle,
  uniSearch,
  uniMoon,
  uniSun,
  uniAngleRight,
  uniAngleDown,
  uniAngleLeft,
  uniExpandArrowsAlt,
  uniClipboardNotes,
  uniPlayCircle,
  uniCopyright,
  uniSort,
  uniFileCheckAlt,
  uniTrashAlt,
  uniSync
])

createApp(App)
  .use(Unicon)
  .provide('axios', axios)
  .use(store)
  .use(router)
  .mount('#app')
