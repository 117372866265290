import { renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Button from '@/components/UI/ButtonComponent.vue'
import { inject } from 'vue'


export default {
  __name: 'DownloadButton',
  props: ['filePath', 'fileName'],
  setup(__props) {

const props = __props;

const axios = inject('axios')



function downloadFile () {
  axios({
    url: props.filePath,
    method: 'GET',
    responseType: 'blob'
  }).then((res) => {
    console.log(res)
    const link = document.createElement('a')
    link.href = URL.createObjectURL(new Blob([res.data]))
    link.setAttribute('download', props.fileName)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
    document.body.removeChild(link)
  })
}

return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(Button, {
    class: "download-button",
    onClick: downloadFile
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}
}

}